import { useDebounceFn } from '@vueuse/core'
import { partialReload } from './use-listing-search'
import { nextTick, ref, watch } from 'vue'
import { FilterActiveOptions, FilterGroup, FilterOption, RangeOption } from '../Pages/the-city/interfaces/Filters'
import { filterGroupType } from '@/Pages/the-city/enums/Filters'
import { useCurrencyWatcher } from '@/Composables/use-currency'

export const filterOptions = ref<FilterActiveOptions[]>([])

export const updateActiveFiltersOptionsSingle = (category: string, data: FilterOption): void => {
    const { title, id } = data

    const index = filterOptions.value.findIndex(item => item.category === category)
    if (index !== -1) {
        if (filterOptions.value[index].id === id) {
            filterOptions.value.splice(index, 1)
        } else {
            filterOptions.value[index].id = id
            filterOptions.value[index].title = title
        }
    } else {
        const filter = { category, id, title }
        filterOptions.value.push(filter)
    }
}

export const updateActiveFiltersOptionsMultiple = (category: string, data: FilterOption): void => {
    const { title, id } = data
    const index = filterOptions.value.findIndex(item => item.category === category && item.id === id)
    if (index !== -1) {
        filterOptions.value.splice(index, 1)
    } else {
        const filter = { category, id, title }
        filterOptions.value.push(filter)
    }
}

export const updateActiveFiltersOptionsRange = (filterData: FilterGroup, data: number[] | null): void => {
    const index = filterOptions.value.findIndex(item => item.category === filterData.category)

    const currencyOptions = { currencyInfo: (filterData.options as RangeOption).currencyInfo }

    if (index !== -1) {
        if (!data) {
            filterOptions.value.splice(index, 1)
            debouncedApplyFilters()
        } else {
            filterOptions.value[index].min = data[0]
            filterOptions.value[index].max = data[1]
            filterOptions.value[index].options = currencyOptions
        }
    } else if (data) {
        const filter = { category: filterData.category, min: data[0], max: data[1], options: currencyOptions }
        filterOptions.value.push(filter)
    }
}

export const resetAllFilters = (): void => {
    filterOptions.value = []
}

export const resetSingleFilter = (data: FilterActiveOptions): void => {
    if (data.category !== filterGroupType.PRICE_RANGE) {
        const index = filterOptions.value.findIndex(item => item.category === data.category && item.id === data.id)
        filterOptions.value.splice(index, 1)
    } else {
        const indexRange = filterOptions.value.findIndex(item => item.category === data.category && item.id === data.id)
        filterOptions.value.splice(indexRange, 1)
    }
}

const sendFilterRequest = ref(false)

export const setSendFilterRequest = (value: boolean): void => {
    sendFilterRequest.value = value
}

const debouncedApplyFilters = useDebounceFn(() => {
    partialReload()
}, 300)

const isPriceRangeReset = ref(false)

export const resetPriceRangeFilter = (): void => {
    const priceRangeIndex = filterOptions.value.findIndex(item => item.category === filterGroupType.PRICE_RANGE)
    if (priceRangeIndex !== -1) {
        isPriceRangeReset.value = true
        filterOptions.value.splice(priceRangeIndex, 1)

        // Reset the flag after the next tick to allow for future price range changes
        nextTick(() => {
            isPriceRangeReset.value = false
        })
    }
}

// Modify the watch to check for price range reset
watch(filterOptions, () => {
    if ((!filterOptions.value.length && !sendFilterRequest.value) || isPriceRangeReset.value) {
        return
    }

    if (sendFilterRequest.value) {
        setSendFilterRequest(false)
    }

    debouncedApplyFilters()
}, { deep: true })

useCurrencyWatcher(() => {
    resetPriceRangeFilter()
})